<template>
    <credor-form acao="ATUALIZAR" @salvar="atualizar($event)" :cancelar="cancelar" :erros="erros" :credor="credor" :projeto="projeto" :isCessaoCredito="isCessaoCredito" :isRecuperacaoJudicial="isRecuperacaoJudicial"></credor-form>
</template>

<script>
import CredoresServices from './services';
import CredorForm from './CredorForm';

export default {
    props: {
        projeto: {
            type: Object,
        },

        isCessaoCredito: {
            type: Boolean,
            default: () => false,
        },

        isRecuperacaoJudicial: {
            type: Boolean,
            default: () => false,
        },
    },

    components: {
        CredorForm,
    },

    data() {
        return {
            erros: [],
            credor: null,
            atualizado: false,
        };
    },

    methods: {
        cancelar() {
            if (this.atualizado) {
                this.$store.dispatch('setAtualizar', true);
            }
            this.$router.push({
                name: 'Credores_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterCredor() {
            this.$store.dispatch('addRequest');
            CredoresServices.obterCredorPorId(this.$route.params.id).then((response) => {
                if (response && response.success) {
                    this.credor = response.data;
                } else {
                    this.credor = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(credorAtualizado) {
            console.log(credorAtualizado);
            this.$store.dispatch('addRequest');
            let credorAtualizadoDto = {
                credorId: this.credor.credorId,
                tipoPessoa: credorAtualizado.tipoPessoa,
                cpfCnpj: credorAtualizado.cpfCnpj,
                identidade: credorAtualizado.identidade,
                identidadeOrgaoEmissor: credorAtualizado.identidadeOrgaoEmissor,
                identidadeUfEmissora: credorAtualizado.identidadeUfEmissora,
                classe: credorAtualizado.classe,
                nome: credorAtualizado.nome,
                situacaoCadastralId: credorAtualizado.situacaoCadastralId,
                naturezaId: credorAtualizado.naturezaId,
                historicoJuridico: credorAtualizado.historicoJuridico,
                valorNominal: credorAtualizado.valorNominal,
                valorCorrigido: credorAtualizado.valorCorrigido,
                precoTarget: credorAtualizado.precoTarget,
                precoMaximo: credorAtualizado.precoMaximo,
                valorQGC: credorAtualizado.valorQGC,
                valorPlano: credorAtualizado.valorPlano,
                contato: credorAtualizado.contato,
                telefone1: credorAtualizado.telefone1,
                telefone2: credorAtualizado.telefone2,
                email1: credorAtualizado.email1,
                email2: credorAtualizado.email2,
                endereco: credorAtualizado.endereco,
                enderecoAtual: credorAtualizado.enderecoAtual,
                statusContatoId: credorAtualizado.statusContatoId,
                statusInteresseId: credorAtualizado.statusInteresseId,
                statusNegociacaoId: credorAtualizado.statusNegociacaoId,
                statusPropostaId: credorAtualizado.statusPropostaId,
                statusCessaoId: credorAtualizado.statusCessaoId,
                origemId: credorAtualizado.origemId,
                statusProcuracaoId: credorAtualizado.statusProcuracaoId,
                responsavel: credorAtualizado.responsavel,
                observacoes: credorAtualizado.observacoes,
            };
            CredoresServices.atualizarCredor(credorAtualizadoDto).then((response) => {
                if (response && response.success) {
                    this.credor = response.data;
                    this.atualizado = true;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Credor',
                        detail: 'Credor atualizado com sucesso',
                        life: 3000,
                    });
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterCredor();
    },
};
</script>
